const posthog = require('posthog-js').default;

let url_createDocusignEnvelopeForSigning = '';
let integrationKey = '';

/**
 * Configure Docusign integration
 *
 * @param config
 */
function configure(config)
{
	//console.log('docusign configured');
	//console.log(config);

	url_createDocusignEnvelopeForSigning = config.url_createDocusignEnvelopeForSigning;
	integrationKey = config.integrationKey;
}

/**
 * This method gets called when a document needs to be signed via Docusign
 */
function quoteAcceptCallback()
{
	$('.docusign_spinner').focus().removeClass('hidden');

	// Make a call back to ChargeOver to create the envelope, and get the signing URL
	let data = {
		quote_token: $('input.handle_quoteToken').val()
	};

	$.post( url_createDocusignEnvelopeForSigning, data, function( resp ) {

		// If an error occurred, just stop
		if (!resp.success)
		{
			$('.docusign-error p').html(resp.errmsg);
			$('.docusign-error').removeClass('hidden');
			return;
		}

		// If this is FALSE, then we don't actually need a signature here
		//  (this happens when e.g. Docusign is tied to a _different event_ other than "quote.before_won"
		if (!resp.data.needs_signature)
		{
			$('#myform_accept').submit();
			return;
		}

		// This hands things off to Docusign to display the document for signing
		_displayEnvelope(resp.data.signing_url);
		return;

	});
}

/**
 * Display the Docusign document for signing
 *
 * @param url
 * @private
 */
async function _displayEnvelope(signingUrl)
{
	// We should track when we know that there is a quote with a related docusign envelope
	posthog.capture('docusign_envelope_request',
		{
			'admin': $('.admin-name').val()
		});

	let clientId = window.ChargeOver.CoC.Integrations.Docusign.integrationKey;

	const signingConfiguration = {
		url: signingUrl,
		displayFormat: 'focused',

		style: {
			/** High-level variables that mirror our existing branding APIs. Reusing the branding name here for familiarity. */
			branding: {
				primaryButton: {
					/** Background color of primary button */
					backgroundColor: '#333',
					/** Text color of primary button */
					color: '#fff',
				}
			},

			/** High-level components we allow specific overrides for */
			signingNavigationButton: {
				finishText: 'Finish and Accept Quote',
				position: 'bottom-left'
			}
		}

	};

	try
	{
		const docusign = await window.DocuSign.loadDocuSign(clientId);

		const signing = docusign.signing(signingConfiguration);

		/** Event handlers **/
		signing.on('ready', (event) => {
			console.log('UI is rendered');

			// Scroll the window down
			window.scrollTo(0, document.body.scrollHeight);
		});

		signing.on('sessionEnd', (event) => {
			console.log('sessionEnd', event)

			// Accept the quote after signing
			if (event.sessionEndType == 'signing_complete' || event.sessionEndType == 'viewing_complete')
			{
				window.location.href = event.returnUrl;
			}

		});

		// Open the signing ceremony
		//$('#main').addClass('hide'); // JQuery to hide content
		//$('#signing-ceremony').removeClass('hide'); // Display the container

		// Display the container
		$('.docusign_spinner').addClass('hidden');
		$('.handle_docusignDocumentContainer').removeClass('hidden');

		// Show the Docusign stuff
		signing.mount('.handle_docusignDocumentContainer');

	}
	catch (error)
	{
		// Any configuration or API limits will be caught here
		console.log("### Error calling docusign.js");
		console.log(error);
	}
}


// This tells Webpack to export these functions so they are
module.exports = {

	// what you want to call it externally : what the function name is internally
	configure: configure,

	quoteAcceptCallback: quoteAcceptCallback
}
